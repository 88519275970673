// ========================================================================================
// Scripts for Site
// ========================================================================================

let year = new Date().getFullYear();
let yearElement = document.getElementById('year');
yearElement.innerHTML = year;

document.addEventListener('DOMContentLoaded', () => {
    const images = [
        './images/slides/slide_1.jpg',
        './images/slides/slide_2.jpg',
        './images/slides/slide_3.jpg',
        './images/slides/slide_4.jpg',
        './images/slides/slide_5.jpg',
        './images/slides/slide_6.jpg',
        './images/slides/slide_7.jpg',
    ];

    const slidesContainer = document.querySelector('.slides');
    const overlay = document.querySelector('.overlay');
    const overlayImage = overlay.querySelector('img');
    const closeButton = overlay.querySelector('.close');
    const prevButton = document.querySelector('.prev');
    const nextButton = document.querySelector('.next');

    images.forEach((src) => {
        const slide = document.createElement('div');
        slide.classList.add('slide');
        const img = document.createElement('img');
        img.src = src;
        img.addEventListener('click', () => {
            overlayImage.src = src;
            overlay.style.display = 'flex';
        });
        slide.appendChild(img);
        slidesContainer.appendChild(slide);
    });

    let index = 0;
    const slidesToShow = 4;
    const slidesToScroll = 1;
    const totalSlides = images.length;

    const updateNavigationButtons = () => {
        prevButton.style.display = index > 0 ? 'block' : 'none';
        nextButton.style.display =
            index < totalSlides - slidesToShow ? 'block' : 'none';
    };

    const showSlide = () => {
        slidesContainer.style.transform = `translateX(${
            -index * (100 / slidesToShow)
        }%)`;

        document.querySelectorAll('.slide').forEach((slide) => {
            slide.classList.remove('last-visible');
        });

        const lastVisibleSlideIndex = index + slidesToShow - 1;
        if (lastVisibleSlideIndex < totalSlides) {
            document
                .querySelectorAll('.slide')
                [lastVisibleSlideIndex].classList.add('last-visible');
        }

        updateNavigationButtons();
    };

    document.querySelector('.next').addEventListener('click', () => {
        if (index < totalSlides - slidesToShow) {
            index += slidesToScroll;
            showSlide();
        }
    });

    document.querySelector('.prev').addEventListener('click', () => {
        if (index > 0) {
            index -= slidesToScroll;
            showSlide();
        }
    });

    closeButton.addEventListener('click', () => {
        overlay.style.display = 'none';
    });

    overlay.addEventListener('click', (event) => {
        if (event.target === overlay) {
            overlay.style.display = 'none';
        }
    });

    showSlide();
});
